<template>
  <div class="editorBox">
    <vue-ueditor-wrap
      v-model="content"
      :config="myConfig"
      :destroy="true"
      @ready="ready"
      ref="ed"
      @beforeInit="addCustomButtom"
    ></vue-ueditor-wrap>
    <input
      style="display: none"
      type="file"
      @change="picChange"
      ref="loadPic"
      id="upFile"
    />
    <input
      style="display: none"
      type="file"
      @change="picMoreChange"
      ref="loadMorePic"
      id="upMoreFile"
      multiple
    />
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import uploadAli from "../../api/uploadAli";
// docx文档预览(只能转换.docx文档，转换过程中复杂样式被忽，居中、首行缩进等)
import mammoth from "mammoth";
export default {
  data() {
    return {
      ueditor: null,
      // content: "", // 富文本编辑器默认内容
      myConfig: this.editConfig,
      myDefaultConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: "100%",
        UEDITOR_HOME_URL: "/UE/",
        // serverUrl: window.location.origin,
        zIndex: 6,
        toolbars: [
          [
            "anchor", //锚点
            "undo", //撤销
            "redo", //重做
            "bold", //加粗
            "indent", //首行缩进
            "snapscreen", //截图
            "italic", //斜体
            "underline", //下划线
            "strikethrough", //删除线
            "subscript", //下标
            "fontborder", //字符边框
            "superscript", //上标
            "formatmatch", //格式刷
            "source", //源代码
            "blockquote", //引用
            "pasteplain", //纯文本粘贴模式
            "selectall", //全选
            "print", //打印
            "preview", //预览
            "horizontal", //分隔线
            "removeformat", //清除格式
            "time", //时间
            "date", //日期
            "unlink", //取消链接
            "insertrow", //前插入行
            "insertcol", //前插入列
            "mergeright", //右合并单元格
            "mergedown", //下合并单元格
            "deleterow", //删除行
            "deletecol", //删除列
            "splittorows", //拆分成行
            "splittocols", //拆分成列
            "splittocells", //完全拆分单元格
            "deletecaption", //删除表格标题
            "inserttitle", //插入标题
            "mergecells", //合并多个单元格
            "deletetable", //删除表格
            "cleardoc", //清空文档
            "insertparagraphbeforetable", //"表格前插入行"
            "insertcode", //代码语言
            "fontfamily", //字体
            "fontsize", //字号
            "paragraph", //段落格式
            // "simpleupload", //单图上传
            // "insertimage", //多图上传
            "edittable", //表格属性
            "edittd", //单元格属性
            "link", //超链接
            // "emotion", //表情
            "spechars", //特殊字符
            "searchreplace", //查询替换
            // "map", //Baidu地图
            // "gmap", //Google地图
            "insertvideo", //视频
            "help", //帮助
            "justifyleft", //居左对齐
            "justifyright", //居右对齐
            "justifycenter", //居中对齐
            "justifyjustify", //两端对齐
            "forecolor", //字体颜色
            "backcolor", //背景色
            "insertorderedlist", //有序列表
            "insertunorderedlist", //无序列表
            "fullscreen", //全屏
            "directionalityltr", //从左向右输入
            "directionalityrtl", //从右向左输入
            "rowspacingtop", //段前距
            "rowspacingbottom", //段后距
            "pagebreak", //分页
            "insertframe", //插入Iframe
            "imagenone", //默认
            "imageleft", //左浮动
            "imageright", //右浮动
            // "attachment", //附件
            "imagecenter", //居中
            "wordimage", //图片转存
            "lineheight", //行间距
            "edittip ", //编辑提示
            "customstyle", //自定义标题
            "autotypeset", //自动排版
            // "webapp", //百度应用
            "touppercase", //字母大写
            "tolowercase", //字母小写
            "background", //背景
            "template", //模板
            // "scrawl", //涂鸦
            // "music", //音乐
            "inserttable", //插入表格
            // "drafts", // 从草稿箱加载
            "charts", // 图表
          ],
        ],
      },
      upLoadType: "img", // img 上传单张图片，moreImg 上传多张图片，video 视频, cover 文章封面, word word文档上传
      editorModule: "",
      editorObj: null,
      editor: null
    };
  },
  created() {
    if(!this.editConfig) this.myConfig = this.myDefaultConfig;
  },
  props: ["editorContent", "editConfig"],
  components: {
    VueUeditorWrap,
  },
  computed: {
    content: {
      get() {
        return this.editorContent ? this.editorContent : "";
      },
      set(val) {
        // let newVal = '<p style="word-break: break-all;">'+val+'</p>'
        this.$emit("update:editorContent", val);
        return val;
      },
    },
  },
  methods: {
    ready(editorInstance) {
      // console.log(`编辑器实例${editorInstance.key}: `, editorInstance);
      this.editor = editorInstance;
      // this.ueditor = editorInstance.editorInstance
      // editorInstance.render('p');//content为id号
      // try {
      //     //添加自定义类名content,用于设置编辑器里的样式
      //     editorInstance.addListener('ready', function (editor) {
      //         (editorInstance.document.body.className += ' content ')
      //     });
      //     editorInstance.sync();
      // } catch (err) {}
      // console.log(window.UE)
      // window.UE.Editor.prototype._bkGetActionUrl = window.UE.Editor.prototype.getActionUrl;
      // window.UE.Editor.prototype.getActionUrl = function(action) {
      //   console.log(action,985211)
      //   const arr = ['uploadimage']
      //   if (arr.indexOf(action) > -1) {
      //     return '/manage/UEditor/editUpload'
      //   } else {
      //     return this._bkGetActionUrlcall(this, action)
      //   }
      // }
    },
    onEditorReady(editor) {
      // 准备编辑器
    },
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    // 内容改变事件
    onEditorChange(editor) {
      console.log(editor)
      this.content = editor.html;
      // this.ruleForm.newBody = this.content;
    },
    addCustomButtom(editorId) {
      var that = this;
      // UE.plugin.register('autosubmit',function(){
      //     return {
      //         shortcutkey:{
      //             "autosubmit":"ctrl+86" //ctrl+v
      //         },
      //         commands:{
      //             'autosubmit':{
      //                 execCommand:function () {
      //                   // that.clipboardEvent();
      //                 }
      //             }
      //         }
      //     }
      // });
      window.UE.registerUI(
        "simpleupload insertimage insertvideo wordupload",
        function (editor, uiName) {
          // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
          editor.registerCommand(uiName, {
            execCommand: function () {
              editor.execCommand("inserthtml", ``);
            },
          });
          let uiName_title;
          switch(uiName) {
            case "simpleupload":
              uiName_title = "单图上传";
              break;
            case "insertimage":
              uiName_title = "多图上传";
              break;
            case "insertvideo":
              uiName_title = "视频上传";
              break;
            case "wordupload":
              uiName_title = "word上传";
              break;
          }
          let btn = new window.UE.ui.Button({
            // 按钮的名字
            name: uiName,
            // 提示
            title: uiName_title,
            // 点击时执行的命令
            onclick: function () {
              // console.log(uiName)
              let upLoadType;
              if(uiName == "simpleupload") {
                upLoadType = "img";
              } else if(uiName == "insertimage") {
                upLoadType = "moreImg";
              } else if(uiName == "insertvideo") {
                upLoadType = "video";
              } else if(uiName == "wordupload") {
                upLoadType = "word";
              }
              that.editorModule = editor;
              that.getLocalPic(upLoadType);
            },
          });

          // 当点到编辑内容上时，按钮要做的状态反射
          editor.addListener("selectionchange", function () {});

          // 因为你是添加 button，所以需要返回这个 button
          return btn;
        },
        46 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */
        /* editorId指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
      );
    },
    getLocalPic(type) {
      this.upLoadType = type;
      if(type == 'img' || type == 'video' || type == 'word') {
        this.$refs.loadPic.value = "";
        this.$refs.loadPic.click();
      } else if(type == 'moreImg') {
        this.$refs.loadMorePic.value = "";
        this.$refs.loadMorePic.click();
      }
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      let ossObj;
      if (this.upLoadType == "img") {
        ossObj = await uploadAli.imgFileUploadOss(files, this.upLoadType);
        this.editorModule.execCommand(
          "inserthtml",
          '<img src="' + ossObj.excelUrl + '" width="600"/>'
        );
      // } else if (this.upLoadType == "cover") {
      //   this.ruleForm.imgUrl = ossUrl;
      } else if(this.upLoadType == 'video') {
        ossObj = await uploadAli.videoFileUploadOss(files, this.upLoadType);
        console.log(ossObj)
        this.editorModule.execCommand(
          "inserthtml",
          '<video src="' + ossObj.excelUrl + '" controls></video>'
        );
      } else {
        let that = this;
        let render = new FileReader()
        // ossObj = await uploadAli.uploadOss(files, this.upLoadType);
        render.onload = function (et) {
          console.log('et: ', et)
          /*
          * 这里可以获取到转为base64格式的文件，
          * 可以将文件绘制于canvas或者将base64格式的文件传给后台
          */
          const arrayBuffer = et.target.result
          // console.log('arrayBuffer: ', arrayBuffer)
          that.wordDownloadEvent(arrayBuffer);
        }
        render.readAsArrayBuffer(files);
      }
    },
    wordDownloadEvent(arrayBuffer) {
      let that = this;
      const mammothOptions = {
        convertImage: mammoth.images.imgElement(function(image) {
          return image.read("base64").then(async (imageBuffer) => {
            const result = await that.uploadBase64Image(imageBuffer, image.contentType);
            return {
              src: result.excelUrl
            }
          });
        })
      };
      mammoth.convertToHtml({ arrayBuffer }, mammothOptions).then((res) => {
        this.$nextTick(() => {
          this.editorModule.execCommand(
            "inserthtml",
            res.value
          );
        });
      });
    },
    async uploadBase64Image(base64Image, mime) {
      const formData = new FormData();
      formData.append("file", this.base64ToBlob(base64Image, mime));
      return await uploadAli.uploadBlobOss(this.base64ToBlob(base64Image, mime), this.upLoadType);
    },
    base64ToBlob(base64, mimeType) {
      let bytes = window.atob(base64);
      let ab = new ArrayBuffer(bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeType });
    },
    async picMoreChange() {
      let inputDOM = this.$refs.loadMorePic;
      let filesArr = inputDOM.files;
      let ossArr = [];
      for(let key in filesArr) {
        if(filesArr[key] instanceof Object && filesArr[key].type) {
          let obj = await uploadAli.imgFileUploadOss(filesArr[key], this.upLoadType);
          ossArr.push(obj);
        }
      }
      ossArr.forEach((item, index) => {
        this.editorModule.execCommand(
          "inserthtml",
          '<img src="' + item.excelUrl + '" width="600"/>'
        );
      })
    },
    async clipboardEvent() {
      let that = this;
      try {
        const clipboardItems = await navigator.clipboard.read();
        // console.log('Pasted content: ', clipboardItems);

        for (const clipboardItem of clipboardItems) {
          // console.log(clipboardItem);
          for (const type of clipboardItem.types) {
            const blob = await clipboardItem.getType(type);
            if(blob.type.includes("image")){
              let item = await uploadAli.uploadBlobOss(blob, 'img');
              console.log(that.editor)
              that.editor.execCommand(
                "inserthtml",
                '<img src="' + item.excelUrl + '" width="600"/>'
              );
            }else{
              return;
              // this.editorModule.execCommand(
              //   "inserthtml",
              //   '<img src="' + item.excelUrl + '" width="600"/>'
              // );
            }
          }
        }
      } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.edui-default .edui-editor-bottomContainer {
  display: none;
}
</style>